<template>
  <div class="home">
    <h2>Please visit the link provided by your instructor or presenter.</h2>
  </div>
</template>

<script>

export default {
  name: 'Home',
  mounted() {

  },
};
</script>

<style scoped>

.home {
  color: white;
  text-align: center;
  padding-top: 15%;
}

</style>
